<template>
  <div>
    <l-moving-marker
      :icon="detectionIcon"
      :ref="`m_${detectionKey}`"
      @click="onSelect"
      :lat-lng="position"
      :class="`${selected ? '' : 'un'}selected-${level}`"
      :duration="650"
    >
      <l-popup
        :options="{ className: 'detection-popup dark', offset: [124, -13] }"
        :ref="`p_${detectionKey}`"
      >
        <slot name="popup" />
      </l-popup>
    </l-moving-marker>
    <l-polyline
      v-if="
        (activeKey === detectionKey || theme.speedLeader.alwaysShow) &&
          showSpeedleader &&
          !isReplay
      "
      :lat-lngs="[[latitude, longitude], [...futurePosition]]"
      :color="theme.speedLeader.color"
      :dashArray="theme.speedLeader.dashArray"
      :weight="theme.speedLeader.weight"
      :opacity="theme.speedLeader.opacity"
    />
  </div>
</template>

<script>
import { divIcon } from 'leaflet'
import { LPolyline, LPopup } from 'vue2-leaflet'
import { LMovingMarker } from 'vue2-leaflet-plugin-movingmarker'
import { mapGetters, mapState, mapActions } from 'vuex'
import { getTheme } from '../utils/themes'
import moment from 'moment'
import { LatLonSpherical } from 'geodesy'

const props = {
  target_id: {
    type: [Number, String]
  },
  drone_id: {
    type: [Number, String]
  },
  correlation_key: {
    type: [Number, String]
  },
  level: {
    type: [Number, String],
    default: 0
  },
  timestamp: {
    type: Number,
    default: 0
  },
  latitude: {
    type: [Number, String],
    default: 0
  },
  longitude: {
    type: [Number, String],
    default: 0
  },
  classification: {
    type: String
  },
  selected: {
    type: Boolean,
    default: false
  },
  markerNumber: {
    type: Number,
    default: 0
  },
  activeKey: {
    type: [Number, String],
    default: null
  },
  state: {
    type: String
  },
  speed: {
    type: Number,
    default: 0
  }
}

// meters
const MIN_DISTANCE_TO_SHOW_SPEEDLEADER = 10

export default {
  name: 'DetectionMarker',
  props,
  inject: {
    isReplay: {
      from: 'isReplay',
      default: false
    }
  },
  data: () => ({
    tracking: false,
    previousLocations: [],
    zeroCount: 0
  }),
  components: {
    LMovingMarker,
    LPopup,
    LPolyline
  },
  computed: {
    ...mapGetters('maps', ['zoom']),
    ...mapState('sensors', { currentActiveKey: 'activeKey' }),
    ...mapGetters('sites', ['activeSite']),
    detectionKey() {
      return this.drone_id || this.correlation_key || this.target_id
    },
    mergedLevel() {
      return this.level || (this.classification === 'drone' && 1)
    },
    position() {
      return [this.latitude, this.longitude]
    },
    img() {
      // const ghost = timestamp && Date.now() - timestamp > 2000 ? "off." : "";
      return `${this.mergedLevel || 0}${
        this.state === 'whitelisted' ? '.whitelisted' : ''
      }.${this.selected === true ? 'select.' : ''}svg`
    },
    milImg() {
      return `detection-${
        this.state === 'whitelisted' ? 'friendly' : 'threat'
      }.svg`
    },
    themeName() {
      return this.$store.state.theme
    },
    theme() {
      return getTheme(this.themeName)
    },
    detectionIcon() {
      let imgHtml
      switch (this.theme.icons.type.detection) {
        case '2525':
          this.addIcon({
            key: this.detectionKey,
            icon: this.milImg
          })
          imgHtml = `<img src="${require('@/assets/mil-std-2525/' +
            this.milImg)}" style="width:50px;height:50px"/>
                <strong class="detection-badge" style="${
                  !this.markerNumber ? 'display: none' : ''
                }">${this.markerNumber}</strong>`
          break
        default:
          this.addIcon({
            key: this.detectionKey,
            icon: this.img
          })
          imgHtml = `<img src="${require('@/assets/drone/' +
            this.img)}" style="width:50px;height:50px"/>
                <strong class="detection-badge" style="${
                  !this.markerNumber ? 'display: none' : ''
                }">${this.markerNumber}</strong>`
      }
      return divIcon({
        className:
          'detection-marker-style' + (this.tracking ? ' trackedTarget' : ''),
        html: imgHtml,
        iconSize: [50, 50]
      })
    },
    showSpeedleader() {
      return (
        [1, 2, 4].includes(parseInt(this.level)) &&
        this.previousLocations.length >= 10 &&
        this.distanceTravelled >= MIN_DISTANCE_TO_SHOW_SPEEDLEADER
      )
    },
    droneSpeed() {
      return this.speed || this.calculatedSpeed
    },
    calculatedSpeed() {
      try {
        if (this.previousLocations.length < 10) {
          return 0
        }

        const endPt = new LatLonSpherical(
          this.previousLocations[0].latitude,
          this.previousLocations[0].longitude
        )
        const startPt = new LatLonSpherical(
          this.previousLocations[1].latitude,
          this.previousLocations[1].longitude
        )
        // distance is in metres
        const distance = startPt.distanceTo(endPt)
        const duration =
          Math.abs(
            moment(this.previousLocations[0].time) -
              moment(this.previousLocations[1].time)
          ) / 1000
        const speed = distance / duration
        // speed is in metres/s
        return speed
      } catch {
        return 0
      }
    },
    futurePosition() {
      const distance = this.droneSpeed * 30
      const position = new LatLonSpherical(this.latitude, this.longitude)
      const futurePosition = position.destinationPoint(distance, this.bearing)
      return [futurePosition.lat, futurePosition.lon]
    },
    bearing() {
      try {
        return this.getBearing(
          {
            latitude: this.previousLocations[9].latitude,
            longitude: this.previousLocations[9].longitude
          },
          {
            latitude: this.previousLocations[0].latitude,
            longitude: this.previousLocations[0].longitude
          }
        )
      } catch {
        return 0
      }
    },
    distanceTravelled() {
      if (this.previousLocations.length === 0) {
        return 0
      }
      const endPt = new LatLonSpherical(
        this.previousLocations[0].latitude,
        this.previousLocations[0].longitude
      )
      const startPt = new LatLonSpherical(
        this.previousLocations[this.previousLocations.length - 1].latitude,
        this.previousLocations[this.previousLocations.length - 1].longitude
      )
      return startPt.distanceTo(endPt)
    }
  },
  methods: {
    ...mapActions('notifications', ['addIcon']),
    getBearing(start, end) {
      const startP = new LatLonSpherical(start.latitude, start.longitude)
      const endP = new LatLonSpherical(end.latitude, end.longitude)
      return startP.bearingTo(endP)
    },
    getAngle(start, end) {
      const y =
        Math.sin(end.longitude - start.longitude) * Math.cos(end.latitude)
      const x =
        Math.cos(start.latitude) * Math.sin(end.latitude) -
        Math.sin(start.latitude) *
          Math.cos(end.latitude) *
          Math.cos(end.longitude - start.longitude)
      const b = Math.atan2(y, x)
      return ((b * 180) / Math.PI + 360) % 360
    },
    onSelect() {
      this.$emit('selectDetection', this.detectionKey)
    },
    handleCameraUpdate(update) {
      const camera = update.message.data[0]
      if (camera.edit_type === 'track') {
        const detectionKey =
          camera.radar_tracked_target || camera.rf_tracked_target
        const detectionMarker = this.$refs[`m_${this.detectionKey}`]
        if (detectionMarker) {
          if (detectionKey === this.detectionKey) {
            detectionMarker.mapObject.options.icon.options.className +=
              ' trackedTarget'
            this.tracking = true
          } else {
            detectionMarker.mapObject.options.icon.options.className.replace(
              ' trackedTarget',
              ''
            )
            this.tracking = false
          }
          detectionMarker.mapObject._initIcon()
        }
      }
    },
    openPopup(key) {
      this.$refs['m_' + key].mapObject.openPopup()
      this.$refs[`p_${key}`].$el.onmouseenter = e => {
        e.toElement.offsetParent.style.setProperty(
          '--detection-popup-transform',
          e.toElement.offsetParent.style.transform
        )
        e.toElement.offsetParent.style.setProperty(
          '--detection-popup-transition',
          'all 0.2s'
        )
      }
      this.$refs[`p_${key}`].$el.onmouseleave = e => {
        setTimeout(() => {
          this.$refs[`p_${key}`].$el.offsetParent.style.setProperty(
            '--detection-popup-transition',
            'none'
          )
        }, 200)
      }
    }
  },
  watch: {
    droneSpeed: {
      handler: function(speed) {
        this.$emit('update:speed', speed)
      },
      immediate: true
    },
    distanceTravelled: {
      handler: function(distance) {
        if (distance < 2) {
          this.zeroCount++
          if (this.zeroCount >= 3) {
            this.previousLocations = []
          }
        } else {
          this.zeroCount = 0
        }
      },
      immediate: true
    },
    position: {
      handler: function() {
        this.previousLocations.unshift({
          latitude: this.latitude,
          longitude: this.longitude,
          time: this.timestamp
        })
        this.previousLocations = this.previousLocations.slice(0, 10)
      },
      immediate: true
    },
    activeKey(v) {
      this.$nextTick(function() {
        if (v && this.$refs[`m_${v}`]) {
          this.openPopup(v)
        } else if (this.$refs[`m_${this.detectionKey}`]) {
          this.$refs[`m_${this.detectionKey}`].mapObject.closePopup()
        }
      })
    }
  },
  mounted() {
    this.$bus.$on('SOCKET/CAMERAS_UPDATE', this.handleCameraUpdate)

    setTimeout(() => {
      if (this.detectionKey === this.activeKey) this.openPopup(this.activeKey)
    }, 125)
  },
  destroyed() {
    this.$bus.$off('SOCKET/CAMERAS_UPDATE', this.handleCameraUpdate)
  }
}
</script>

<style>
/* css to customize Leaflet default styles  */

.detection-popup {
  transition: var(--detection-popup-transition) !important;
}

.detection-popup:hover {
  transform: var(--detection-popup-transform) !important;
}

.detection-popup .leaflet-popup-content-wrapper {
  min-width: 300px;
  background: rgba(48, 48, 48, 0.8);
  color: #fff;
  border-left: 4px solid orange;
  border-radius: 0px;
}

.proposal-popup .leaflet-popup-content-wrapper {
  min-width: 100px;
  min-height: 30px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 0px;
}

.proposal-popup .leaflet-popup-content {
  margin: 3px 5px 3px 5px;
  user-select: none;
}

.detection-popup .leaflet-popup-content {
  margin: 3px 0px 0px 0px;
  width: auto !important;
}

.detection-popup b {
  padding-left: 1.5em;
  margin: 10px 10px 10px 0px;
  display: inline-block;
}

.detection-popup .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}

.proposal-popup .leaflet-popup-close-button,
.proposal-popup .leaflet-popup-tip {
  display: none;
}

.dot {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-left: 3px;
  margin-right: 3px;
  width: 8px;
}

.rf-dot {
  background: #0080f7;
}

.cannon-dot {
  background: #ff7e27;
}

.discovair-dot {
  background: #9b34eb;
}

.radar-dot {
  background: #fa001b;
}

.camera-dot {
  background: rgb(170, 178, 65);
}

.camera-dot-opt2 {
  background: -webkit-linear-gradient(
    top,
    rgb(170, 178, 65) 50%,
    rgb(126, 111, 168) 50%
  );
}

.trackedTarget {
  animation: pulse 1.5s ease infinite;
  border-radius: 100%;
}

@keyframes pulse {
  0% {
    box-shadow: inset 0px 0px 2px rgba(227, 155, 54, 10);
  }
  100% {
    box-shadow: inset 0px 0px 25px rgba(227, 155, 54, 50);
  }
}
</style>
