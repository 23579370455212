<template>
  <l-marker
    :icon="originIcon"
    :lat-lng="position"
    @click="originClicked"
    :class="`${selected ? '' : 'un'}selected-1`"
  />
</template>

<script>
import { divIcon } from 'leaflet'
import { LMarker } from 'vue2-leaflet'

const props = {
  selected: {
    type: Boolean,
    default: false
  },
  markerNumber: {
    type: Number,
    default: 0
  },
  position: {
    type: Array,
    default: () => []
  },
  state: {
    type: String
  }
}

export default {
  name: 'OriginMarker',
  props,
  components: {
    LMarker
  },
  computed: {
    img() {
      return this.state === 'whitelisted' ? require(`@/assets/${'origin.whitelisted.png'}`) : require(`@/assets/${'origin.png'}`)
    },
    originIcon() {
      return divIcon({
        className: 'detection-marker-style',
        html: `<img src="${this.img}" style="width:35px;height:35px"/>
              <strong class="detection-badge" style="${
                !this.markerNumber ? 'display: none' : ''
              }">${this.markerNumber}</strong>`,
        iconSize: [35, 35],
        iconAnchor: [7, 35]
      })
    }
  },
  methods: {
    originClicked() {
      this.$emit('originClicked')
    }
  }
}
</script>
<style>
/* css to customize Leaflet default styles  */
.detection-marker-style {
  background: transparent;
}

.detection-badge {
  position: absolute;
  top: -5px;
  right: -8px;
  padding: 3px 5px;
  border: 1px solid white;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.4),
    0 0 1px rgba(0, 0, 0, 0.7) inset, 0 10px 0px rgba(255, 255, 255, 0.11) inset;
  -webkit-background-clip: padding-box;
  font: bold 10px 'Helvetica Neue', sans-serif;
  color: white;
  text-decoration: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
}
</style>
